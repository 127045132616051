@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
font-family: 'Poppins', sans-serif;
/* property allows us to include the padding and border in an element's total width and height */
box-sizing: border-box;
/* reset */
margin: 0;
padding: 0;
font-weight: 300;

}

.contact-sd {
  margin-top: 7rem;
}

body {
   background-image: linear-gradient(to right, #bd52ee , #f6f7a9);
	

	margin: 0;
}

.learn {
        background-color: #bd52ee; /* Green */
        border: none;
        color: white;
        margin-top: 10px;
        padding: 10px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        cursor: pointer;
      }

      .learn:hover {
        background-color: #f6f7a9;
        color: black;
      }

      .avatar {
        margin: auto;
        width: fit-content;
        animation: float 6s ease-in-out infinite;
      }





.nav {
  background: rgba(255, 255, 255, 0.46);
        
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
  color:#2c333b ;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
}

.site-title {
  font-size: 2rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;

}

.nav a {
  color: inherit;
  font-weight: 400;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.center {
        margin-top: 16rem;
        text-align: center;
        width: auto;
      }

      .center-contact {
        margin-top: 10rem;
        text-align: center;
        width: auto;
      }



.nav li.active a {
  font-weight: bold;
}

@keyframes float {
        0% {
          transform: translatey(0px);
        }
        50% {
          transform: translatey(-20px);
        }
        100% {
          transform: translatey(0px);
        }
      }

        footer {
        color: black;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 1.2rem;
        text-align: center;
        background: rgba(255, 255, 255, 0.46);

        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
      }

